     <template>
      <section class="innerpage-wrap pt-0">
        <div class="container" ref="savePrimaryInfo">
          <!--      <enrollment-nav></enrollment-nav>-->
          <div class="split-title">
            <h1 class="brand-color">Personal Information</h1>
          </div>
          <div class="enrollmentForm card-block">
            <form action="">
              <div class="form-group row">
                <!--  First Name section -->
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.first_name.required == true">
                  <label>First Name <span class="required">*</span></label>
                  <input type="text" class="custom-input"
                         placeholder="Enter First Name"
                         id="fname"
                         @input="$v.formFields.first_name.value.$touch(); removeError();"
                         :class="{'form-control is-invalid':$v.formFields.first_name.value.$error || formErrors['first_name_message']}"
                         v-model="formFields.first_name.value" value="">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                    Please enter valid first name
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['first_name_message']}}</div>
                </div>

                <!--  Middle Name section -->
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4 mt-1" v-if="showMiddleName">
                  <label>Middle Name </label>
                  <input type="text" class="custom-input"
                         placeholder="Enter Middle Name"
                         id="middleName"
                         @input="removeError();"
                         :class="{'form-control is-invalid':formErrors['middle_name_message']}"
                         v-model="formFields.middle_name.value" >
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['middle_name_message']}}</div>
                </div>
                <!--  Last Name section -->
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.last_name.required == true">
                  <label>Last Name <span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         placeholder="Enter Last Name"
                         @input="$v.formFields.last_name.value.$touch(); removeError();"
                         v-bind:class="{'form-control is-invalid':$v.formFields.last_name.value.$error || formErrors['last_name_message']}"
                         id="Lname" v-model="formFields.last_name.value"
                         value="" >
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                    Please enter valid last name
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['last_name_message']}}</div>
                </div>
              </div>


              <!--  Weight Section -->
              <div class="form-group row" v-if="formFields.weight.required == true">
                <!--            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.weight.required == true">-->
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4">
                  <label>Weight (lbs) <span class="required">*</span></label>
                  <input type="number"
                         class="custom-input"
                         :class="{'form-control is-invalid':$v.formFields.weight.value.$error || formErrors['weight_message']}"
                         placeholder="Enter Weight"
                         @input="$v.formFields.weight.value.$touch(); removeError();"
                         id="weight"
                         min="1"
                         v-model="formFields.weight.value"
                         oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="3">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.weight.value.required && $v.formFields.weight.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.weight.value.required && $v.formFields.weight.value.$error">
                    Weight cannot be zero or less
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">
                    {{formErrors['weight_message']}}
                  </div>
                </div>

                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4">
                  <label>Height <span class="required">*</span></label>
                  <div class="height-input" :class="{'invalid-header-align':$v.formFields.height_feet.value.$error || formErrors['height_feet_message']
                  || $v.formFields.height_inch.value.$error || formErrors['height_inch_message']}">
                    <span class="feet">Feet:</span>
                    <div :class="{'invalid-align':$v.formFields.height_feet.value.$error || formErrors['height_feet_message']}">
                      <input type="number"
                             class="custom-input"
                             placeholder="Feet"
                             id="height"
                             @input="$v.formFields.height_feet.value.$touch(); removeError();"
                             :class="{'form-control is-invalid':$v.formFields.height_feet.value.$error || formErrors['height_feet_message']}"
                             v-model="formFields.height_feet.value" value="6"
                             min="1"
                             oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="1">
                      <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.height_feet.value.required && $v.formFields.height_feet.value.$error">
                        Field is required
                      </div>
                      <div class="form-group__message  ml-3 mt-2" style="font-size: 11px;"
                           v-if="$v.formFields.height_feet.value.required && $v.formFields.height_feet.value.$error">
                        Height cannot be zero
                      </div>
                      <div class="error_message" v-if="formErrorFlag == true">{{formErrors['height_feet_message']}}</div>
                    </div>
                    <span class="feet">Inches:</span>
                    <div :class="{'invalid-align':$v.formFields.height_inch.value.$error || formErrors['height_inch_message']}">
                      <input type="number"
                             class="custom-input"
                             placeholder="Inch"
                             @input="$v.formFields.height_inch.value.$touch(); removeError();"
                             v-bind:class="{'form-control is-invalid ':$v.formFields.height_inch.value.$error || formErrors['height_inch_message']}"
                             v-model="formFields.height_inch.value"
                             value="2" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="2">
                      <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.height_inch.value.required && $v.formFields.height_inch.value.$error">
                        Field is required
                      </div>
                      <div class="form-group__message  ml-3 mt-2" style="font-size: 11px;"
                           v-if="$v.formFields.height_inch.value.required && $v.formFields.height_inch.value.$error">
                        Inch is invalid
                      </div>
                      <div class="error_message" v-if="formErrorFlag == true">{{formErrors['height_inch_message']}}</div>
                    </div>
                  </div>
                  <!--              <input type="text" class="custom-input" placeholder="Ft.Inch (eg: 5.10)" name="" id="">-->
                </div>

                <!--  Gender Section -->
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.gender.required == true">
                  <label>Gender <span class="required">*</span></label>
                  <select name="" class="custom-input" id="gender" readonly="readonly" :disabled="true" v-model="formFields.gender.value" >
                    <option value="null">Select Gender</option>
                    <option value="0">Male</option>
                    <option value="1">Female</option>
                    <option value="2">Others</option>
                  </select>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['gender_message']}}</div>
                </div>
              </div>

                  <!--  Marital Status section -->
              <div class="form-group row">
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.marital_status.required == true">
                  <label>Marital Status <span class="required">*</span></label>
                  <b-form-select v-model="formFields.marital_status.value"
                                 class="custom-input"
                                 @input="$v.formFields.marital_status.value.$touch();removeError();"
                                 v-bind:class="{'form-control is-invalid':$v.formFields.marital_status.value.$error || formErrors['marital_status_message']}"
                                 id="marital_status"
                                 :options="maritalStatusOptions">
                  </b-form-select>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.marital_status.value.required && $v.formFields.marital_status.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['marital_status_message']}}</div>
                </div>

                <!--  SSN section -->
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.ssn.required == true">
                  <label>Social Security Number <span class="required">*</span></label>
                  <input type="number"
                         class="custom-input"
                         name=""
                         id="ssn"
                         placeholder="Enter SSN Number"
                         :class="{'form-control is-invalid':$v.formFields.ssn.value.$error || formErrors['ssn_message']}"
                         @input="$v.formFields.ssn.value.$touch();removeError();"
                         v-model="formFields.ssn.value"
                         value=""
                         min="0"
                         oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                         maxlength="9">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                    Invalid Social Security Number
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['ssn_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.birthdate.required == true">
                  <label>Date of Birth <span class="required">*</span></label>
                      <input
                          type="text"
                          v-model="formFields.birthdate.value"
                          class="custom-input"
                          disabled
                          placeholder="mm/dd/yyyy"
                          size=10 maxlength=10
                      />
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['birthdate_message']}}</div>
                </div>
              </div>

              <!--  Prior coverage flag section -->
              <div class="form-group" v-if="formFields.prior_coverage_flag.required == true">
                <!--          <div class="form-group">-->
                <h5>Within the last 12 months I have had</h5>
                <div class="form-check form-check-inline mt-3">
                  <input class="form-check-input"
                         type="radio"
                         name="inlineRadioOptions"
                         id="inlineRadio1"
                         v-model="formFields.prior_coverage_flag.value" value="0"
                         :checked="formFields.prior_coverage_flag.value == 0">
                  <label class="form-check-label" for="inlineRadio1">No prior Health Coverage</label>
                </div>
                <div class="form-check form-check-inline mt-3">
                  <input class="form-check-input"
                         type="radio"
                         name="inlineRadioOptions"
                         id="inlineRadio2"
                         v-model="formFields.prior_coverage_flag.value" value="1"
                         :checked="formFields.prior_coverage_flag.value == 1">
                  <label class="form-check-label" for="inlineRadio2" >Have had Insurance Carrier</label>
                </div>
                <div class="error_message" v-if="formErrorFlag == true">{{formErrors['prior_coverage_flag_message']}}</div>
                <div class="form-group row" v-if="formFields.prior_coverage_flag.value ==1">
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.prior_carrier_name.required == true">
                    <!--            <div class="input_box carrier_name" >-->
                    <label for="">Carrier Name<span class="required">*</span></label>
                    <input class="custom-input"
                           type="text"
                           placeholder="Enter Carrier Name"
                           @input="removeError();"
                           :class="{'form-control is-invalid':formErrors['prior_carrier_name_message']}"
                           id="carrier_name"
                           v-model="formFields.prior_carrier_name.value"
                           value="">
                    <div class="error_message mt-2" v-if="formErrorFlag == true">{{formErrors['prior_carrier_name_message']}}</div>
                  </div>

                  <!--  Effective Date Section -->
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.prior_effective_date.required == true">
                    <label>Effective Date<span class="required">*</span></label>
                    <v-date-picker
                        v-model="formFields.prior_effective_date.value"
                        :class="{'invalid':formErrors['prior_effective_date_message']}"
                        :popover="{ visibility: 'click' }"
                        :max-date="new Date()"
                        @input="removeError(); convertEffectiveToDateFormat();"
                        mode="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="custom-input"
                            placeholder="mm/dd/yyyy"
                            size=10 maxlength=10
                            :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                            v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                    <div class="error_message mt-2" v-if="formErrorFlag == true">{{formErrors['prior_effective_date_message']}}</div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.prior_term_date.required == true">
                    <label>Date of Termination <span class="required">*</span></label>
                      <v-date-picker
                          v-model="formFields.prior_term_date.value"
                          :class="{'invalid': formErrors['prior_term_date_message']}"
                          :popover="{ visibility: 'click' }"
                          :max-date="new Date()"
                          @input="removeError(); convertPriorTermToDateFormat();"
                          mode="date">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                              class="custom-input"
                              placeholder="mm/dd/yyyy"
                              size=10 maxlength=10
                              :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                              v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>
                    <div class="error_message mt-2" v-if="formErrorFlag == true">{{formErrors['prior_term_date_message']}}</div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="mt-3" style="text-align: right;" v-if="(enrollerGroupId == '2556' && enrollerCodeRequired) || (enrollerGroupId == '2512' && enrollerCodeRequired)">
            <span class="form-group__message">Enroller code is required</span>
          </div>
          <div class="action-btn split-title mt-4">
            <button class="dark-btn" type="button" id="backConfirm" name="button" @click="navigatePrevious">Back</button>
            <div class="action-next">
              <button v-if="eProcess=='rep' || enrollerGroupId == '2556' || enrollerGroupId == '2512'"
                      type="button" name="button" class="button-brand mx-2" @click="openEnroller">Enter Enroller Code</button>
              <button class="button-brand" type="button" id="savePersonal" name="button" @click="saveData">Next</button>
            </div>
          </div>
          <b-modal v-bind:hide-footer="true" centered size="md" id="quote-confirmation">
            <template #modal-header>
              <div class="popUp-header text-center">
                <h4>Enroller Code</h4>
                <b-button variant="close-btn" @click="$bvModal.hide('quote-confirmation')">
                  <i class="fas fa-times"></i>
                </b-button>
              </div>
            </template>
            <b-container fluid>
              <div class="planPop-details">
                <div class="content">
                  <input type="text"
                         class="custom-input"
                         :class="{'form-control is-invalid': isEnrollerCodeEmpty || enrollerCodeErr}"
                         @input="removeError()"
                         v-model="enrollerCode"
                         value="" >
                  <div class="error_message mt-2" v-if="isEnrollerCodeEmpty">
                    Enter Enroller Code
                  </div>
                  <div class="error_message mt-2" v-if="enrollerCodeErr == true">{{enrollerCodeErrMsg}}</div>
                  <div class="popUp_button mt-3">
                    <b-button class="univ-btn" variant="primary" @click="saveEnrollerCode">Save</b-button>
                  </div>
                </div>
              </div>
            </b-container>
          </b-modal>
        </div>
      </section>
    </template>

    <script>
    import { required } from 'vuelidate/lib/validators'
    import {checkSsn, validName, checkZero, checkInch} from "../../utils/validators";
    import axios from 'axios';
    import html2canvas from 'html2canvas';
    export default {
      name: "PersonalInformation",
      props: {
        navdata: {
          type: Array
        }
      },
      data: function () {
        return {
          tempId: '',
          // formFields: [],
          formFields: {
            first_name: {
              value: '',
              required: false,
              read_only: false,
            },
            middle_name: {
              value: '',
              required: false,
              read_only: false,
            },
            last_name: {
              value: '',
              required: false,
              read_only: false,
            },
            birthdate: {
              value: '',
              required: false,
              read_only: false,
            },
            gender: {
              value: '',
              required: false,
              read_only: false,
            },
            height_feet: {
              value: null,
              required: false,
              read_only: false,
            },
            height_inch: {
              value: null,
              required: false,
              read_only: false,
            },
            weight: {
              value: null,
              required: false,
              read_only: false,
            },
            marital_status: {
              value: '',
              required: false,
              read_only: false,
            },
            ssn: {
              value: '',
              required: false,
              read_only: false,
            },
            prior_coverage_flag: {
              value: '',
              required: false,
              read_only: false,
            },
            prior_carrier_name: {
              value: '',
              required: false,
              read_only: false,
            },
            prior_effective_date: {
              value: '',
              required: false,
              read_only: false,
            },
            prior_term_date: {
              value: '',
              required: false,
              read_only: false,
            }
          },
          formErrorFlag: false,
          formErrors: [],
          codePopup: false,
          saveSuccess: false,
          enrollerCode: null,
          enrollerCodeErr: false,
          enrollerCodeErrMsg: '',
          eProcess: '',
          format:'MM/dd/yyyy',
          maritalStatusOptions: [
            { value: null, text: 'Choose Marital Status', disabled: true},
            { value: '0', text: 'Single'},
            { value: '1', text: 'Married'},
            { value: '2', text: 'Divorced'},
            { value: '3', text: 'Widowed'}
          ],
          showMiddleName: false,
          isEnrollerCodeEmpty: false,
          enrollerGroupId: null,
          enrollerCodeRequired: false
        }
      },
      mounted() {
        let app = this;
        app.tempId = window.localStorage.getItem('tempId');
        app.enrollerCode = window.localStorage.getItem('enrollerCode');
        app.scrollTop();
        app.getFields();
        app.checkEprocess();
        app.fetchInfo();
        app.getEnrollerGroupId();
        // let jqDate = app.formFields.birthdate;
      },
      validations() {
       if (this.formFields.weight.required) {
          return {
            formFields: {
              first_name: {
                value: {
                  required,
                  validName
                }
              },
              last_name: {
                value: {
                  required,
                  validName
                }
              },
              marital_status: {
                value: {
                  required
                }
              },
              ssn: {
                value: {
                  required,
                  checkSsn
                }
              },
              weight: {
                value: {
                  required,
                  checkZero
                }
              },
              height_feet: {
                value: {
                  required,
                  checkZero
                }
              },
              height_inch: {
                value: {
                  required,
                  checkInch
                }
              }
            }
          }
        }  else {
        return {
          formFields: {
            first_name: {
              value: {
                required,
                validName
              }
            },
            last_name: {
              value: {
                required,
                validName
              }
            },
            marital_status: {
              value: {
                required
              }
            },
            ssn: {
              value: {
                required,
                checkSsn
              }
            }
          }
        }
      }
    },
    methods: {
      checkValue: function(str, max) {
        if (str.charAt(0) !== '0' || str == '00') {
          var num = parseInt(str);
          if (isNaN(num) || num <= 0 || num > max) num = 1;
          str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
        }
        return str;
      },
      getFields: function () {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE+'/get-form-fields-personal-info', {
          params: {
            enrollment_id: app.tempId,
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.showMiddleName = true
                response.data.data.result.forEach(function(item) {
                  if(item.required == true){
                    app.formFields[item.field_name] = item;
                  }
                });
              }
              app.fetchInfo();
              console.log(app.formFields);
            })
            .catch(function (error) {
              console.log(error);
            });
      },
      eventDob: function() {
        let app = this;
        var input = app.formFields.birthdate.value;
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
        var values = input.split('/').map(function(v) {
          return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = app.checkValue(values[0], 12);
        if (values[1]) values[1] = app.checkValue(values[1], 31);
        var output = values.map(function(v, i) {
          return v.length == 2 && i < 2 ? v + '/' : v;
        });
        app.formFields.birthdate.value = output.join('').substr(0, 10);
      },
      fetchInfo: function () {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'primary_info',
          }
        })
            .then(function (response) {
              let fetchedData = [];
              if (response.data.status == 'success') {
                for(var i in response.data.data.result) {
                  fetchedData.push([i, response.data.data.result [i]]);
                }
                console.log('******** fetch data ******', fetchedData);
                fetchedData.forEach(function(item) {
                  let key = item[0];
                  let val = item[1];
                  app.formFields[key]['value'] = val;
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      },
      getEnrollerGroupId: function () {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'group_id',
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.enrollerGroupId = response.data.data.result;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      },
      saveData: function () {
        console.log('***** Inside Save Data *****', this.formFields)
        // Validating the field with its respective validation
        let app = this;
        app.checkForEnrollerCode();
        if (this.$v.$invalid || app.enrollerCodeRequired) {
          this.$v.$touch();
          console.log('***** Inside Save Data Invalid*****')
          return false
        } else {
          console.log('***** Inside Save Data valid*****')
          const el = this.$refs.savePrimaryInfo;
          (async () => {
            this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'primary_info');
            this.screenShotData = (await html2canvas(el)).toDataURL();
          })();
          // Submit API
          app.formErrorFlag = false;
          app.formErrors = [];
          let params = {
            enrollment_id: app.tempId,
            first_name: app.formFields.first_name.value,
            middle_name: app.formFields.middle_name.value,
            last_name: app.formFields.last_name.value,
            birthdate: app.formFields.birthdate.value,
            gender: app.formFields.gender.value,
            height_feet: app.formFields.height_feet.value,
            height_inch: app.formFields.height_inch.value,
            weight: app.formFields.weight.value,
            marital_status: app.formFields.marital_status.value,
            ssn: app.formFields.ssn.value,
            prior_coverage_flag: app.formFields.prior_coverage_flag.value,
            prior_carrier_name: app.formFields.prior_carrier_name.value,
            prior_effective_date: app.formFields.prior_effective_date.value,
            prior_term_date: app.formFields.prior_term_date.value,
          };
          //discard null values
          for (const key of Object.keys(params)) {
            if (params[key] === null) {
              delete params[key];
            }
          }
          console.log(params);
          axios.post(process.env.VUE_APP_API_BASE + '/save-personal-info', params)
              .then(function (response) {
                if (response.data.status == 'success') {
                  console.log("success");
                  app.saveAndContinue();
                }
              })
              .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        }
      },
      checkForEnrollerCode () {
        let app = this
        if (app.enrollerGroupId == '2556' || app.enrollerGroupId == '2512') {
          if (app.enrollerCode == '' || app.enrollerCode == null) {
            app.enrollerCodeRequired = true
          }
        }
      },
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        if (localStorage.getItem("navto") === null || localStorage.getItem("navto") === undefined || localStorage.getItem("navto") === '') {
          this.$emit('comEnvNavigation', this.$route.name);
        }else{
          window.localStorage.removeItem('navto');
          this.$router.push('review-confirm');
        }
      },
      openEnroller : function () {
        let app = this;
        app.saveSuccess = false;
        app.$bvModal.show('quote-confirmation');
      },
      closePopup : function () {
        let app = this;
        app.codePopup = false;
      },
      checkEprocess: function() {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'eprocess',
          }
        })
            .then(function (response) {
              console.log(response.data.data.result);
              if (response.data.status == 'success') {
                app.eProcess = response.data.data.result;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      },
      saveEnrollerCode : function () {
        if (this.enrollerCode === '' || this.enrollerCode === null) {
           this.isEnrollerCodeEmpty = true;
        } else {
          let app = this;
          axios.post(process.env.VUE_APP_API_BASE + '/save-enroller-code', {
            enrollment_id: app.tempId,
            enrollerCode: app.enrollerCode
          })
              .then(function (response) {
                app.enrollerCodeRequired = true
                if (response.data.status == 'success') {
                  app.saveSuccess = true;
                  console.log("success");
                  localStorage.setItem('enrollerCode', app.enrollerCode)
                  app.codePopup = false;
                  app.enrollerCodeErr = false;
                  app.enrollerCodeErrMsg = '';
                  app.enrollerCodeRequired = false;
                  app.$bvModal.hide('quote-confirmation');
                }
              })
              .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                  app.enrollerCodeRequired = true;
                  error.response.data.data.forEach(function (item) {
                    app.enrollerCodeErr = true;
                    app.enrollerCodeErrMsg = item.error_message;
                  });
                }
              });
        }
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$router.push('/cart');
      },
      saveScreenShot (image, name) {
        let app = this;
        let data = {
          "enrollment_id" : app.tempId,
          "name" : name,
          "image" : image
        };
        let config = {
          method: 'post',
          url: process.env.VUE_APP_API_BASE+'/save-screenshot',
          headers: { },
          data : data
        };
        axios(config)
            .then(function (response) {
              if (response.data.status == 'success') {
                console.log('screenshot saved');
              }
            })
            .catch(function (error) {
              console.log('screenshot not saved');
              console.log(error);
            });
      },
      scrollTop(){
        window.scrollTo(0,0);
      },
      removeError () {
        this.formErrors = []
        this.isEnrollerCodeEmpty = false
        this.enrollerCodeErr = false
      },
      convertEffectiveToDateFormat () {
        let app = this
        let date = app.formFields.prior_effective_date.value
        let dd = date.getDate()
        let mm = date.getMonth() + 1
        let yyyy = date.getFullYear()
        if (dd < 10) {
          dd = '0' + dd
        }
        if (mm < 10) {
          mm = '0' + mm
        }
        app.formFields.prior_effective_date.value = mm + '/' + dd + '/' + yyyy;
      },
      convertPriorTermToDateFormat () {
        let app = this
        let date = app.formFields.prior_term_date.value
        let dd = date.getDate()
        let mm = date.getMonth() + 1
        let yyyy = date.getFullYear()
        if (dd < 10) {
          dd = '0' + dd
        }
        if (mm < 10) {
          mm = '0' + mm
        }
        app.formFields.prior_term_date.value = mm + '/' + dd + '/' + yyyy;
      }
    },
    watch: {
      enrollerCode: function () {
        if(this.enrollerCode === null || this.enrollerCode === '') {
          this.isEnrollerCodeEmpty = true
        } else {
          this.isEnrollerCodeEmpty = false
        }
       }
      }
    }
    </script>

    <style scoped>
    .invalid-align {
      position: relative;
      top: 15px;
    }
    .invalid-header-align {
      position: relative;
      top: -13px;
    }
    </style>
