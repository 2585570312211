var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-wrap"},[_c('div',{staticClass:"banner-wrap"},[_c('div',{staticClass:"alert-primary-box"},[_c('div',{staticClass:"row",staticStyle:{"padding":"10px"},attrs:{"align":"center"}},[_c('div',{staticClass:"col-sm-6 left-list"},[_c('table',{attrs:{"width":"100%","border":"0","cellpadding":"10","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('h2',[_c('strong',[_c('font',{attrs:{"color":"white"}},[_vm._v("Dial Up Your Peace of Mind")])],1)])])]),_c('tr',[_vm._m(0),_c('td',[_c('h3',[_c('font',{attrs:{"color":"white"}},[_vm._v("Overwhelmed, stressed, or anxious?")])],1)])]),_c('tr',[_vm._m(1),_c('td',[_c('h3',[_c('font',{attrs:{"color":"white"}},[_vm._v("Need to talk to a Doctor?")])],1)])]),_c('tr',[_vm._m(2),_c('td',[_c('h3',[_c('font',{attrs:{"color":"white"}},[_vm._v("Family or Marriage Issues?")])],1)])]),_c('tr',[_vm._m(3),_c('td',[_c('h3',[_c('font',{attrs:{"color":"white"}},[_vm._v("Struggling with PTSD?")])],1)])]),_c('tr',[_vm._m(4),_c('td',[_c('h3',[_c('font',{attrs:{"color":"white"}},[_vm._v("Having negative thoughts?")])],1)])])])])]),_vm._m(5)])])]),_vm._m(6),_c('featured-plans',{staticClass:"mt-4"}),_c('recommended-plans',{staticClass:"mt-4"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('i',{staticClass:"qode_icon_font_awesome fa fa-check qode-ili-icon-holder transparent",staticStyle:{"font-size":"30px","color":"#fff"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('i',{staticClass:"qode_icon_font_awesome fa fa-check qode-ili-icon-holder transparent",staticStyle:{"font-size":"30px","color":"#fff"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('i',{staticClass:"qode_icon_font_awesome fa fa-check qode-ili-icon-holder transparent",staticStyle:{"font-size":"30px","color":"#fff"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('i',{staticClass:"qode_icon_font_awesome fa fa-check qode-ili-icon-holder transparent",staticStyle:{"font-size":"30px","color":"#fff"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('i',{staticClass:"qode_icon_font_awesome fa fa-check qode-ili-icon-holder transparent",staticStyle:{"font-size":"30px","color":"#fff"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-sm-2",staticStyle:{"display":"inline-block","padding":"10px"}},[_c('video',{staticStyle:{"height":"auto   !important","width":"100%    !important"},attrs:{"controls":"","preload":"auto","poster":"https://corenroll.com/img/teletherapy-postrt.PNG"}},[_c('source',{attrs:{"src":"https://corenroll.com/video/TeleTherapy123.mp4","type":"video/mp4"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert-info info-tele",attrs:{"role":"alert"}},[_c('h3',[_vm._v(" We have the answers for you, with 3 award winning TeleHealth Products to give you peace of mind from the comfort of home for you and your family's well-being. ")])])
}]

export { render, staticRenderFns }